.profile_tabs {
  /* color: #fff; */
  height: 50px;
  min-width: 200px;
  /* width: auto; */
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  /* background-color: #a61717; */
  margin: 15px 3px;
  border: 1px solid rgb(79, 79, 233);
  clip-path: polygon(0% 0%, 60% 0%, 90% 0%, 100% 50%, 90% 100%, 60% 100%, 0% 100%);
  cursor: pointer;
}
.activeTab{
  color: #fff;
  background-color: rgb(79, 79, 233);
}